import React from "react";
import Header from "./Header";
import GameTypeWrapper from "./GameTypeWrapper";
import GameType from "./GameType";
import ScreenSizeWarn from "../../ScreenSizeWarn";
import './Home.css'

function Home() {
  return (
    <>
      <ScreenSizeWarn />
      <div className="home">
        <Header />
        <GameTypeWrapper>
          <GameType type="one-suite" heading="One Suit" />
          <GameType type="four-suite" heading="Four Suit" />
          <GameType type="klondike" heading="Klondike" />
        </GameTypeWrapper>
        <div className="game-details">
          <a className="game-details__link" href='./about.html'>About Game</a>
          <a className="game-details__link" href='./privacy.html'>Privacy Policy</a>
        </div>
      </div>
    </>
  );
}

export default Home;
