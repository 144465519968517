import React from "react";
import { Link } from "react-router-dom";
import oneSuit from "../../../assets/one-suite.png";
import fourSuit from "../../../assets/four-suite.png";
import klondike from "../../../assets/klondike.png";
import "./GameType.css";

function GameType({ type, heading }) {
  return (<>
    <Link className="gametype__removedecoration" to={type}>
      <div className="gametype">
        <img
          className="gametype__image"
          src={(type=="one-suite"? oneSuit : null)||type=="four-suite"? fourSuit : null ||type=="klondike"? klondike : null 
          }
        />
        <div className="gametype__link">
          <h3> {heading} Solitaire </h3>
        </div>
      </div>
     
    </Link>
  
    </>);
}

export default GameType;
