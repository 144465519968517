import React from "react";
import { Link } from "react-router-dom";
import backIcon from "../assets/back.png"
import "./Back.css";

function Back() {
  return (
		<div>
			<Link to={'/'} className="back">
					<img src={backIcon} alt="back to menu" className="back-img" />
					Menu
			</Link>
		</div>
  );
}

export default Back;
